import bcrypt from 'bcryptjs';

const key = 'refineair_secret';

const url = () => {
    if (process.env.NODE_ENV === 'production') {
        return "http://212.237.36.200:8080/refineair/api/v1";
    } else {
        return "http://localhost:8080/refineair/api/v1";
    }
}

const publicMediaPath = () => {
    if (process.env.NODE_ENV === 'production') {
        return 'http://212.237.36.200/img/';
    } else {
        return 'http://localhost/img/';
    }
}

const secretKey = () => {
    const salt = bcrypt.genSaltSync(10);
    const hashedKey = bcrypt.hashSync(key, salt);
    return hashedKey;
}

export const properties = {
    API_URL: url(),
    secretKey: secretKey(),
    tokenKey: 'refineair-management-token',
    errorMessage: 'Errore connessione, contattare l\'amministratore.',
    expiredSessionMessage: 'Sessione scaduta.',
    successMessage: 'Salvato correttamente',
    publicMediaPath: publicMediaPath()
}