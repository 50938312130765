import {
    Avatar,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    makeStyles,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import CustomContext from '../context/CustomContext';
import { useSnackbar } from "notistack";
import { properties } from "../configs/properties";
import { FaInfoCircle, FaPlusCircle, FaUserCircle } from "react-icons/fa";
import NumberFormatCustom from "../components/NumberFormatCustom";
import { Autocomplete } from "@material-ui/lab";
import moment from 'moment';
import Transition from "../components/Transition";

const useStyle = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(8),
    },
    fullWidth: {
        width: "100%",
    },
    downloadPdf: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
}));

const CreateReport = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [azienda, setAzienda] = useState("");
    const [dataReport, setDataReport] = useState(new Date());
    const [numeroRapporto, setNumeroRapporto] = useState("");
    const [tipoAmbiente, setTipoAmbiente] = useState("");
    const [tipoAmbienteEnum, setTipiAmbienteEnum] = useState([]);
    const [puntoCampionamento, setPuntoCampionamento] = useState("");
    const [puntiCampionamentoEnum, setPuntiCampionamentoEnum] = useState([]);
    const [contaMicrorganismi, setContaMicrorganismi] = useState('');
    const [contaLieviti, setContaLieviti] = useState('');
    const [contaMicrorganismiDateFrom, setContaMicrorganismiDateFrom] = useState(
        new Date()
    );
    const [contaMicrorganismiDateTo, setContaMicrorganismiDateTo] = useState(
        new Date()
    );
    const [contaLievitiDateFrom, setContaLievitiDateFrom] = useState(new Date());
    const [contaLievitiDateTo, setContaLievitiDateTo] = useState(new Date());
    const [
        controlloMicrobiologicoDateFrom,
        setControlloMicrobiologicoDateFrom,
    ] = useState(new Date());
    const [
        controlloMicrobiologicoDateTo,
        setControlloMicrobiologicoDateTo,
    ] = useState(new Date());
    const [controlloMicrobiologico, setControlloMicrobiologico] = useState(null);
    const [clienti, setClienti] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [eseguitoDa, setEseguitoDa] = useState('');
    const [drBiologo, setDrBiologo] = useState('');

    const [aziendaError, setAziendaError] = useState(false);
    const [numeroRapportoError, setNumeroRapportError] = useState(false);
    const [clienteError, setClienteError] = useState(false);
    const [tipoAmbienteError, setTipoAmbienteError] = useState(false);
    const [puntoCampionamentoError, setPuntoCampionamentoError] = useState(false);
    const [contaMicrorganismiError, setContaMicrorganismiError] = useState(false);
    const [contaLievitiError, setContaLievitiError] = useState(false);
    const [controlloMicrobiologicoError, setControlloMicrobiologicoError] = useState(false);
    const [dataError, setDataError] = useState(false);
    const [contaMicrorganismiDateFromError, setContaMicrorganismiDateFromError] = useState(false);
    const [contaMicrorganismiDateToError, setContaMicrorganismiDateToError] = useState(false);
    const [contaLievitiDateFromError, setContaLievitiDateFromError] = useState(false);
    const [contaLievitiDateToError, setContaLievitiDateToError] = useState(false);
    const [controlloMicrobiologicoDateFromError, setControlloMicrobiologicoDateFromError] = useState(false);
    const [controlloMicrobiologicoDateToError, setControlloMicrobiologicoDateToError] = useState(false);
    const [eseguitoDaError, setEseguitoDaError] = useState(false);
    const [drBiologoError, setDrBiologoError] = useState(false);
    const [tipoAmbienteAdd, setTipoAmbienteAdd] = useState('');
    const [tipoAmbienteAddError, setTipoAmbienteAddError] = useState(false);
    const [puntoCampionamentoAdd, setPuntoCampionamentoAdd] = useState('');
    const [puntoCampionamentoAddError, setPuntoCampionamentoAddError] = useState(false);

    const [downloadPdf, setDownloadPdf] = useState(true);

    const [addTipoAmbienteDialogOpen, setAddTipoAmbienteDialogOpen] = useState(false);
    const [addPuntoCampionamentoDialogOpen, setAddPuntoCampionamentoDialogOpen] = useState(false);

    const handleChangeTipoAmbienteAdd = (e) => {
        setTipoAmbienteAddError(false);
        setTipoAmbienteAdd(e.target.value);
    }

    const handleChangePuntoCampionamentoAdd = (e) => {
        setPuntoCampionamentoAddError(false);
        setPuntoCampionamentoAdd(e.target.value);
    }

    const handleCloseAddTipoAmbienteDialog = () => {
        setTipoAmbienteAdd('');
        setTipoAmbienteAddError(false);
        setAddTipoAmbienteDialogOpen(false);
    }

    const handleCloseAddPuntoCampionamentoDialog = () => {
        setPuntoCampionamentoAdd('');
        setPuntoCampionamentoAddError(false);
        setAddPuntoCampionamentoDialogOpen(false);
    }

    const handleClickSaveAddTipoAmbiente = () => {
        if (!tipoAmbienteAdd || tipoAmbienteAdd?.length === 0) {
            setTipoAmbienteAddError(true);
        } else {
            setLoading(true);
            ctx.tipoAmbienteExists(tipoAmbienteAdd)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes != null) {
                        if (jsonRes === false) {
                            ctx.saveTipoAmbiente({ nome: tipoAmbienteAdd, cancellato: false })
                                .then(res => {
                                    if (res.status === 200) {
                                        return res.json();
                                    } else if (res.status === 401) {
                                        setLoading(false);
                                        ctx.logout();
                                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                                    } else {
                                        setLoading(false);
                                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                        return null;
                                    }
                                })
                                .then(jsonRes => {
                                    if (jsonRes) {
                                        setLoading(false);
                                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                                        handleCloseAddTipoAmbienteDialog();
                                        initEnums();
                                    }
                                })
                        } else {
                            setLoading(false);
                            enqueueSnackbar('Tipo ambiente già esistente.', { variant: 'warning' });
                        }

                    }
                })

        }
    }

    const handleClickSaveAddPuntoCampionamento = () => {
        if (!puntoCampionamentoAdd || puntoCampionamentoAdd?.length === 0) {
            setPuntoCampionamentoAddError(true);
        } else {
            setLoading(true);
            ctx.puntoCampionamentoExists(puntoCampionamentoAdd)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes != null) {
                        if (jsonRes === true) {
                            setLoading(false);
                            enqueueSnackbar('Punto campionamento già esistente.', { variant: 'warning' });
                        } else {
                            ctx.savePuntoCampionamento({ nome: puntoCampionamentoAdd, cancellato: false })
                                .then(res => {
                                    if (res.status === 200) {
                                        return res.json();
                                    } else if (res.status === 401) {
                                        setLoading(false);
                                        ctx.logout();
                                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                                    } else {
                                        setLoading(false);
                                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                        return null;
                                    }
                                })
                                .then(jsonRes => {
                                    if (jsonRes) {
                                        setLoading(false);
                                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                                        handleCloseAddPuntoCampionamentoDialog();
                                        initEnums();
                                    }
                                })
                        }
                    }
                })

        }
    }

    const initEnums = () => {
        setLoading(true);
        ctx.getCreateReportEnums()
            .then(res => {
                if (res?.status === 200) {
                    return res.json();
                } else if (res?.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    // error
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setTipiAmbienteEnum(jsonRes.tipoAmbienteDomainList);
                    setPuntiCampionamentoEnum(jsonRes.puntoCampionamentoDomainList);
                    setAzienda(jsonRes.azienda);
                    setClienti(jsonRes.clienti);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        initEnums();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeTipoAmbiente = (e) => {
        if (e.target.value !== -1) {
            setTipoAmbienteError(false);
            setTipoAmbiente(e.target.value);
        } else {
            setTipoAmbiente('');
            setAddTipoAmbienteDialogOpen(true);
        }
    };

    const handleChangePuntoCampionamento = (e) => {
        if (e.target.value !== -1) {
            setPuntoCampionamentoError(false);
            setPuntoCampionamento(e.target.value);
        } else {
            setPuntoCampionamento('');
            setAddPuntoCampionamentoDialogOpen(true);
        }
    };

    const handleChangeAzienda = (e) => {
        setAziendaError(false);
        setAzienda(e.target.value);
    };

    const handleChangeNumeroRapporto = (e) => {
        setNumeroRapportError(false);
        setNumeroRapporto(e.target.value);
    };

    const handleChangeControlloMicrobiologico = (e) => {
        setControlloMicrobiologicoError(false);
        setControlloMicrobiologico(e.target.value)
    }

    const handleChangeContamicrorganismi = (e) => {
        setContaMicrorganismiError(false);
        setContaMicrorganismi(e.target.value);
    }

    const handleChangeContaLieviti = (e) => {
        setContaLievitiError(false);
        setContaLieviti(e.target.value);
    }

    const handleChangeCliente = (e, newValue) => {
        setClienteError(false);
        setCliente(newValue);
    }

    const handleChangeEseguitoDa = (e) => {
        setEseguitoDaError(false);
        setEseguitoDa(e.target.value);
    }

    const handleChangeDrBiologo = (e) => {
        setDrBiologoError(false);
        setDrBiologo(e.target.value);
    }

    const handleChangeDataReport = (data) => {
        setDataReport(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setDataError(false);
        } else {
            setDataError(true);
        }
    }

    const handleChangeContaMicrorganismiDateFrom = (data) => {
        setContaMicrorganismiDateFrom(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setContaMicrorganismiDateFromError(false);
        } else {
            setContaMicrorganismiDateFromError(true);
        }
    }

    const handleChangeContaMicrorganismiDateTo = (data) => {
        setContaMicrorganismiDateTo(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setContaMicrorganismiDateToError(false);
        } else {
            setContaMicrorganismiDateToError(true);
        }
    }

    const handleChangeContaLievitiDateFrom = (data) => {
        setContaLievitiDateFrom(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setContaLievitiDateFromError(false);
        } else {
            setContaLievitiDateFromError(true);
        }
    }

    const handleChangeContaLievitiDateTo = (data) => {
        setContaLievitiDateTo(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setContaLievitiDateToError(false);
        } else {
            setContaLievitiDateToError(true);
        }
    }

    const handleChangeControlloMicrobiologicoDateFrom = (data) => {
        setControlloMicrobiologicoDateFrom(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setControlloMicrobiologicoDateFromError(false);
        } else {
            setControlloMicrobiologicoDateFromError(true);
        }
    }

    const handleChangeControlloMicrobiologicoDateTo = (data) => {
        setControlloMicrobiologicoDateTo(data);
        if (moment(data, 'dd/MM/yyyy', true).isValid()) {
            setControlloMicrobiologicoDateToError(false);
        } else {
            setControlloMicrobiologicoDateToError(true);
        }
    }

    const handleChangeDownloadPdf = (e) => {
        setDownloadPdf(e.target.checked);
    }

    const validation = () => {
        let result = true;

        if (!azienda || azienda?.length === 0) {
            result = false;
            setAziendaError(true);
        }

        if (!eseguitoDa || eseguitoDa?.length === 0) {
            result = false;
            setEseguitoDaError(true);
        }

        if (!drBiologo || drBiologo?.length === 0) {
            result = false;
            setDrBiologoError(true);
        }

        if (!numeroRapporto || numeroRapporto?.length === 0) {
            result = false;
            setNumeroRapportError(true);
        }

        if (!cliente || cliente?.length === 0) {
            result = false;
            setClienteError(true);
        }

        if (!tipoAmbiente || tipoAmbiente?.length === 0) {
            result = false;
            setTipoAmbienteError(true);
        }

        if (!puntoCampionamento || puntoCampionamento?.length === 0) {
            result = false;
            setPuntoCampionamentoError(true);
        }

        if (!contaMicrorganismi || contaMicrorganismi?.length === 0) {
            result = false;
            setContaMicrorganismiError(true);
        }

        if (!contaLieviti || contaLieviti?.length === 0) {
            result = false;
            setContaLievitiError(true);
        }

        if (!controlloMicrobiologico || controlloMicrobiologico?.length === 0) {
            result = false;
            setControlloMicrobiologicoError(true);
        }

        if (!dataReport || !moment(dataReport, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setDataError(true)
        }

        if (!contaMicrorganismiDateFrom || !moment(contaMicrorganismiDateFrom, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setContaMicrorganismiDateFromError(true);
        }

        if (!contaMicrorganismiDateTo || !moment(contaMicrorganismiDateTo, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setContaMicrorganismiDateToError(true);
        }

        if (!contaLievitiDateFrom || !moment(contaLievitiDateFrom, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setContaLievitiDateFromError(true);
        }

        if (!contaLievitiDateTo || !moment(contaLievitiDateTo, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setContaLievitiDateToError(true);
        }

        if (!controlloMicrobiologicoDateFrom || !moment(controlloMicrobiologicoDateFrom, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setControlloMicrobiologicoDateFromError(true);
        }

        if (!controlloMicrobiologicoDateTo || !moment(controlloMicrobiologicoDateTo, 'dd/MM/yyyy', true).isValid()) {
            result = false;
            setControlloMicrobiologicoDateToError(true);
        }

        return result;
    }

    const modelReport = () => {
        return {
            id: null,
            azienda: azienda,
            data: moment(dataReport).format('yyyy-MM-DD'),
            numeroReport: numeroRapporto,
            utenteInstallatoreDomain: null,
            utenteUtilizzatoreDomain: { id: cliente.id },
            puntoCampionamentoDomain: { id: puntoCampionamento },
            tipoAmbienteDomain: { id: tipoAmbiente },
            contaMicrorganismi: contaMicrorganismi,
            contaMicrorganismiDataInizio: moment(contaMicrorganismiDateFrom).format('yyyy-MM-DD'),
            contaMicrorganismiDataFine: moment(contaMicrorganismiDateTo).format('yyyy-MM-DD'),
            contaLieviti: contaLieviti,
            contaLievitiDataInizio: moment(contaLievitiDateFrom).format('yyyy-MM-DD'),
            contaLievitiDataFine: moment(contaLievitiDateTo).format('yyyy-MM-DD'),
            controlloMicrobiologico: controlloMicrobiologico,
            controlloMicrobiologicoDataInizio: moment(controlloMicrobiologicoDateFrom).format('yyyy-MM-DD'),
            controlloMicrobiologicoDataFine: moment(controlloMicrobiologicoDateTo).format('yyyy-MM-DD'),
            cancellato: false,
            eseguitoDa: eseguitoDa,
            drBiologo: drBiologo
        }
    }

    const resetForm = () => {
        setDataReport(new Date());
        setCliente(null);
        setNumeroRapporto('');
        setTipoAmbiente('');
        setPuntoCampionamento('');
        setContaMicrorganismi('');
        setContaMicrorganismiDateFrom(new Date());
        setContaMicrorganismiDateTo(new Date());
        setContaLieviti('');
        setContaLievitiDateFrom(new Date());
        setContaLievitiDateTo(new Date());
        setControlloMicrobiologico('');
        setControlloMicrobiologicoDateFrom(new Date());
        setControlloMicrobiologicoDateTo(new Date());
        setDownloadPdf(true);
        setEseguitoDa('');
        setDrBiologo('');
    }

    const handleClickSave = () => {
        if (validation()) {
            setLoading(true);
            let report = modelReport();
            ctx.saveNewReport(report)
                .then(res => {
                    if (res.status === 200) {
                        return res.blob();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(blob => {
                    if (blob) {
                        if (downloadPdf) {
                            let url = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = url;
                            a.download = 'report.pdf';
                            a.click();
                        }
                        setLoading(false);
                        enqueueSnackbar(properties.successMessage, { variant: 'success' });
                        resetForm();
                    }
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        } else {
            enqueueSnackbar('Compilare correttamente i campi.', { variant: 'warning' })
        }
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <form className={classes.form} noValidate>
                <Typography variant="h4" color='textSecondary'>{"Inserimento dati report"}</Typography>
                <Grid
                    container
                    spacing={3}
                    justify="space-around"
                    className={classes.container}
                >
                    <Grid item lg={12} className={classes.fullWidth}>
                        <Card raised className={classes.card}>
                            <CardHeader
                                title="Info report"
                                titleTypographyProps={{ variant: "h6" }}
                            />
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="azienda"
                                        label="Azienda"
                                        name="azienda"
                                        value={azienda}
                                        onChange={handleChangeAzienda}
                                        error={aziendaError}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="nr_rapporto"
                                        label="Nr. rapporto"
                                        name="nr_rapporto"
                                        value={numeroRapporto}
                                        onChange={handleChangeNumeroRapporto}
                                        error={numeroRapportoError}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="eseguito_da"
                                        label="Eseguito da"
                                        name="eseguito_da"
                                        value={eseguitoDa}
                                        onChange={handleChangeEseguitoDa}
                                        error={eseguitoDaError}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <DatePicker
                                        margin="normal"
                                        id="data_rapporto"
                                        format="dd/MM/yyyy"
                                        value={dataReport}
                                        onChange={handleChangeDataReport}
                                        error={dataError}
                                        helperText="Data report"
                                        fullWidth
                                        cancelLabel="ANNULLA"
                                        style={{
                                            paddingLeft: theme.spacing(1),
                                            paddingRight: theme.spacing(1),
                                        }}
                                    />
                                    <Autocomplete
                                        id="clienti"
                                        options={clienti}
                                        value={cliente}
                                        onChange={handleChangeCliente}
                                        getOptionLabel={(option) => (option.nome + ' ' + option.cognome)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Cliente"
                                                variant="outlined"
                                                fullWidth
                                                margin='normal'
                                                error={clienteError}
                                            />
                                        }
                                        renderOption={(option) =>
                                            <React.Fragment>
                                                <Avatar style={{ marginRight: '10px' }}>
                                                    <FaUserCircle />
                                                </Avatar>
                                                {option.nome + ' ' + option.cognome}
                                            </React.Fragment>
                                        }
                                        ListboxProps={{
                                            style: {
                                                border: '1px solid rgba(0,0,0,0.2)',
                                                borderRadius: '3px'
                                            }
                                        }}
                                    />
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id="dr_biologo"
                                        label="Dottore biologo"
                                        name="dr_biologo"
                                        value={drBiologo}
                                        onChange={handleChangeDrBiologo}
                                        error={drBiologoError}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item lg={12} className={classes.fullWidth}>
                        <Card raised className={classes.card}>
                            <CardHeader
                                title="Info prelievo"
                                titleTypographyProps={{ variant: "h6" }}
                            />
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id="tipo_ambiente"
                                        select
                                        label="Tipo di ambiente"
                                        value={tipoAmbiente}
                                        onChange={handleChangeTipoAmbiente}
                                        error={tipoAmbienteError}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                    >
                                        <MenuItem value={-1}>
                                            <FaPlusCircle style={{ marginRight: theme.spacing(1) }} />
                                            <em>{'Aggiungi'}</em>
                                        </MenuItem>
                                        {tipoAmbienteEnum.map((ta) => {
                                            return (
                                                <MenuItem key={ta.id} value={ta.id}>
                                                    {ta.nome}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id="punto_campionamento"
                                        select
                                        label="Punto di campionamento"
                                        value={puntoCampionamento}
                                        onChange={handleChangePuntoCampionamento}
                                        error={puntoCampionamentoError}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                    >
                                        <MenuItem value={-1}>
                                            <FaPlusCircle style={{ marginRight: theme.spacing(1) }} />
                                            <em>{'Aggiungi'}</em>
                                        </MenuItem>
                                        {puntiCampionamentoEnum.map((pc) => {
                                            return (
                                                <MenuItem key={pc.id} value={pc.id}>
                                                    {pc.nome}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item lg={12} className={classes.fullWidth}>
                        <Card raised className={classes.card}>
                            <CardHeader
                                title="Dati prelievo"
                                titleTypographyProps={{ variant: "h6" }}
                            />
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Conta microrganismi"
                                        id="conta_microrganismi"
                                        helperText="UFC/Tampone"
                                        value={contaMicrorganismi}
                                        onChange={handleChangeContamicrorganismi}
                                        error={contaMicrorganismiError}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end' >
                                                <Tooltip title='Conta microrganismi a 30° C ISO18593:2004 (escluso par. 6 e 7) + UNI EN ISO4833-1:2013' placement='top'>
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <FaInfoCircle style={{ color: 'gray' }} />
                                                    </div>
                                                </Tooltip>
                                            </InputAdornment>,
                                            inputComponent: NumberFormatCustom,
                                            autoComplete: 'off'
                                        }}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid container item lg={6}>
                                    <Grid
                                        item
                                        sm={6}
                                        className={classes.fullWidth}
                                        style={{
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2),
                                        }}
                                    >
                                        <DatePicker
                                            margin="normal"
                                            id="contaMicrorganismiDateFrom"
                                            format="dd/MM/yyyy"
                                            value={contaMicrorganismiDateFrom}
                                            onChange={handleChangeContaMicrorganismiDateFrom}
                                            error={contaMicrorganismiDateFromError}
                                            helperText="Data inizio"
                                            fullWidth
                                            cancelLabel="ANNULLA"
                                            maxDate={contaMicrorganismiDateTo}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        className={classes.fullWidth}
                                        style={{
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2),
                                        }}
                                    >
                                        <DatePicker
                                            margin="normal"
                                            id="contaMicrorganismiDateTo"
                                            format="dd/MM/yyyy"
                                            value={contaMicrorganismiDateTo}
                                            onChange={handleChangeContaMicrorganismiDateTo}
                                            error={contaMicrorganismiDateToError}
                                            helperText="Data fine"
                                            fullWidth
                                            cancelLabel="ANNULLA"
                                            minDate={contaMicrorganismiDateFrom}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Conta lieviti e muffe"
                                        id="conta_lieviti"
                                        helperText="UFC/Tampone"
                                        value={contaLieviti}
                                        onChange={handleChangeContaLieviti}
                                        error={contaLievitiError}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end' >
                                                <Tooltip title='Conta lieviti e muffe  ISO18593:2004 (escluso par. 6 e 7) + UNI EN ISO21527-2:2008' placement='top'>
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <FaInfoCircle style={{ color: 'gray' }} />
                                                    </div>
                                                </Tooltip>
                                            </InputAdornment>,
                                            inputComponent: NumberFormatCustom,
                                            autoComplete: 'off'
                                        }}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid container item lg={6}>
                                    <Grid
                                        item
                                        sm={6}
                                        className={classes.fullWidth}
                                        style={{
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2),
                                        }}
                                    >
                                        <DatePicker
                                            margin="normal"
                                            id="contaLievitiDateFrom"
                                            format="dd/MM/yyyy"
                                            value={contaLievitiDateFrom}
                                            onChange={handleChangeContaLievitiDateFrom}
                                            error={contaLievitiDateFromError}
                                            helperText="Data inizio"
                                            fullWidth
                                            cancelLabel="ANNULLA"
                                            maxDate={contaLievitiDateTo}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        className={classes.fullWidth}
                                        style={{
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2),
                                        }}
                                    >
                                        <DatePicker
                                            margin="normal"
                                            id="contaLievitiDateTo"
                                            format="dd/MM/yyyy"
                                            value={contaLievitiDateTo}
                                            onChange={handleChangeContaLievitiDateTo}
                                            error={contaLievitiDateToError}
                                            helperText="Data fine"
                                            fullWidth
                                            cancelLabel="ANNULLA"
                                            minDate={contaLievitiDateFrom}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container>
                                <Grid
                                    item
                                    lg={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label="Controllo microbiologico"
                                        id="controllo_microbiologico"
                                        helperText="UFC/Tampone e/o dm2"
                                        value={controlloMicrobiologico}
                                        onChange={handleChangeControlloMicrobiologico}
                                        error={controlloMicrobiologicoError}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                            autoComplete: 'off'
                                        }}
                                        autoComplete='off'
                                    />
                                </Grid>
                                <Grid container item lg={6}>
                                    <Grid
                                        item
                                        sm={6}
                                        className={classes.fullWidth}
                                        style={{
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2),
                                        }}
                                    >
                                        <DatePicker
                                            margin="normal"
                                            id="controlloMicrobiologicoDateFrom"
                                            format="dd/MM/yyyy"
                                            value={controlloMicrobiologicoDateFrom}
                                            onChange={handleChangeControlloMicrobiologicoDateFrom}
                                            error={controlloMicrobiologicoDateFromError}
                                            helperText="Data inizio"
                                            fullWidth
                                            cancelLabel="ANNULLA"
                                            maxDate={controlloMicrobiologicoDateTo}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        className={classes.fullWidth}
                                        style={{
                                            paddingLeft: theme.spacing(2),
                                            paddingRight: theme.spacing(2),
                                        }}
                                    >
                                        <DatePicker
                                            margin="normal"
                                            id="controlloMicrobiologicoDateTo"
                                            format="dd/MM/yyyy"
                                            value={controlloMicrobiologicoDateTo}
                                            onChange={handleChangeControlloMicrobiologicoDateTo}
                                            error={controlloMicrobiologicoDateToError}
                                            helperText="Data fine"
                                            fullWidth
                                            cancelLabel="ANNULLA"
                                            minDate={controlloMicrobiologicoDateFrom}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <FormControlLabel
                    control={
                        <Switch
                            name='downloadPdf'
                            checked={downloadPdf}
                            color='primary'
                            onChange={handleChangeDownloadPdf}
                        />
                    }
                    label='Download PDF'
                    className={classes.downloadPdf}
                />

                <Button
                    onClick={handleClickSave}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress size="2em" color='primary' />
                    ) : (
                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                        )}
                </Button>
            </form>
            <Dialog
                open={addTipoAmbienteDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAddTipoAmbienteDialog}
                aria-labelledby="add-tipo-ambiente-dialog"
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>{`Aggiungi Tipo Ambiente`}</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="tipo_ambiente"
                                label="Tipo Ambiente"
                                name="tipo_ambiente"
                                value={tipoAmbienteAdd}
                                onChange={handleChangeTipoAmbienteAdd}
                                error={tipoAmbienteAddError}
                            />
                            <Grid
                                style={{
                                    marginTop: theme.spacing(2),
                                    marginBottom: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    onClick={handleCloseAddTipoAmbienteDialog}
                                    variant="contained"
                                    disabled={isLoading}
                                >
                                    {'Annulla'}
                                </Button>
                                <Button
                                    onClick={handleClickSaveAddTipoAmbiente}
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <CircularProgress size="2em" color='primary' />
                                    ) : (
                                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                                        )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog
                open={addPuntoCampionamentoDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseAddPuntoCampionamentoDialog}
                aria-labelledby="add-punto-campionamento-dialog"
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>{`Aggiungi Punto Campionamento`}</DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                id="punto_campionamento"
                                label="Punto Campionamento"
                                name="punto_campionamento"
                                value={puntoCampionamentoAdd}
                                onChange={handleChangePuntoCampionamentoAdd}
                                error={puntoCampionamentoAddError}
                            />
                            <Grid
                                style={{
                                    marginTop: theme.spacing(2),
                                    marginBottom: theme.spacing(2),
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Button
                                    onClick={handleCloseAddPuntoCampionamentoDialog}
                                    variant="contained"
                                    disabled={isLoading}
                                >
                                    {'Annulla'}
                                </Button>
                                <Button
                                    onClick={handleClickSaveAddPuntoCampionamento}
                                    variant="contained"
                                    color="primary"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <CircularProgress size="2em" color='primary' />
                                    ) : (
                                            <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                                        )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

export default CreateReport;
