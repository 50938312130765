import { Button, Card, CardHeader, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, makeStyles, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import CustomContext from '../context/CustomContext';
import { properties } from '../configs/properties';
import Transition from '../components/Transition';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    innerContainer: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    fullWidth: {
        width: "100%",
    },
    submit: {
        marginTop: theme.spacing(4),
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    }
}))

const CompleteProfile = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [checkEmail, setCheckEmail] = useState(true);

    const [nomeError, setNomeError] = useState(false);
    const [cognomeError, setCognomeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleChangeNome = (e) => {
        setNomeError(false);
        setNome(e.target.value);
    }

    const handleChangeCognome = (e) => {
        setCognomeError(false);
        setCognome(e.target.value);
    }

    const handleChangeEmail = (e) => {
        let emailValid = validateEmail(e.target.value);
        setEmailError(!emailValid);
        setEmail(e.target.value);
    }

    const handleChangePassword = (e) => {
        setPasswordError(false);
        setPassword(e.target.value);
    }

    const validateEmail = (m) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(m)) {
            return true;
        }
        return false;
    }

    const validation = () => {
        let result = true;

        if (nome?.length === 0) {
            result = false;
            setNomeError(true);
        }

        if (cognome?.length === 0) {
            result = false;
            setCognomeError(true);
        }

        if (!validateEmail(email)) {
            result = false;
            setEmailError(true);
        }

        if (password?.length === 0) {
            result = false;
            setPasswordError(true);
        }
        return result;
    }

    const handleSave = () => {
        if (validation()) {
            setLoading(true);
            if (checkEmail) {
                ctx.emailExists(email)
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 401) {
                            setLoading(false);
                            ctx.logout();
                            enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                        } else {
                            setLoading(false);
                            enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                            return null;
                        }
                    })
                    .then(exists => {
                        if (exists !== null && exists === true) {
                            setLoading(false);
                            enqueueSnackbar('Email già in uso.', { variant: 'warning' });
                        } else if (exists !== null && exists === false) {
                            ctx.completeProfile({
                                nome: nome,
                                cognome: cognome,
                                email: email,
                                password: password
                            })
                                .then(res => {
                                    if (res.status === 200) {
                                        return res.json();
                                    } else if (res.status === 401) {
                                        setLoading(false);
                                        ctx.logout();
                                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                                    } else {
                                        setLoading(false);
                                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                        return null;
                                    }
                                })
                                .then(jsonRes => {
                                    if (jsonRes) {
                                        setLoading(false);
                                        enqueueSnackbar(properties.successMessage, { variant: 'success' })
                                        setSuccessDialogOpen(true);
                                    }
                                })
                                .catch(e => {
                                    setLoading(false);
                                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                                })
                        }
                    })
                    .catch(e => {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    })
            } else {
                ctx.completeProfile({
                    nome: nome,
                    cognome: cognome,
                    email: email,
                    password: password
                })
                    .then(res => {
                        if (res.status === 200) {
                            return res.json();
                        } else if (res.status === 401) {
                            setLoading(false);
                            ctx.logout();
                            enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                        } else {
                            setLoading(false);
                            enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                            return null;
                        }
                    })
                    .then(jsonRes => {
                        if (jsonRes) {
                            setLoading(false);
                            enqueueSnackbar(properties.successMessage, { variant: 'success' })
                            setSuccessDialogOpen(true);
                        }
                    })
                    .catch(e => {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    })
            }
        } else {
            enqueueSnackbar('Compilare correttamente i campi.', { variant: 'warning' });
        }
    }

    useEffect(() => {
        setLoading(true);
        ctx.getUserLogged()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setNome(jsonRes.nome ? jsonRes.nome : '');
                    setCognome(jsonRes.cognome ? jsonRes.cognome : '');
                    setEmail(jsonRes.email ? jsonRes.email : '');
                    if (jsonRes.email?.length > 0) {
                        setCheckEmail(false);
                    } else {
                        setCheckEmail(true);
                    }
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container}>
            <Typography variant="h4" color='textSecondary'>{"Completa profilo"}</Typography>
            <Grid
                container
                spacing={3}
                justify="space-around"
                className={classes.innerContainer}
            >
                <Grid item lg={12} className={classes.fullWidth}>
                    <Card raised className={classes.card}>
                        <CardHeader
                            title="Profilo utente"
                            titleTypographyProps={{ variant: "h6" }}
                        />
                        <Grid container>
                            <Grid
                                item
                                container
                                xs={12}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='nome'
                                        label='Nome'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={nome}
                                        onChange={handleChangeNome}
                                        error={nomeError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='cognome'
                                        label='Cognome'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={cognome}
                                        onChange={handleChangeCognome}
                                        error={cognomeError}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='email'
                                        label='Email'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={email}
                                        onChange={handleChangeEmail}
                                        error={emailError}
                                        disabled={!checkEmail}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={6}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        id='password'
                                        label='Password'
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        value={password}
                                        onChange={handleChangePassword}
                                        error={passwordError}
                                        type='password'
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.fullWidth}
                                    style={{
                                        paddingLeft: theme.spacing(1),
                                        paddingRight: theme.spacing(1),
                                        paddingBottom: theme.spacing(1),
                                        justifyContent: 'flex-end',
                                        display: 'flex'
                                    }}
                                >
                                    <Button
                                        onClick={handleSave}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <CircularProgress size="2em" color='primary' />
                                        ) : (
                                                <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                                            )}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Dialog
                open={successDialogOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="success-dialog"
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle>{'Profilo completato'}</DialogTitle>
                <DialogContent>
                    <Typography variant='h6' color='textSecondary'>{`Esegui il login con le tue nuove credenziali per accedere ai tuoi dati.`}</Typography>

                    <Button
                        onClick={() => { ctx.logout() }}
                        variant='contained'
                        color='primary'
                        disabled={isLoading}
                        style={{ float: 'right', marginTop: theme.spacing(3) }}
                    >
                        <span style={{ fontWeight: 'bold' }}>{'Ok'}</span>
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CompleteProfile;