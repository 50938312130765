import { Card, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { properties } from '../configs/properties';
import CustomContext from '../context/CustomContext';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    cardContent: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', padding: theme.spacing(2)
    }
}))

const Logs = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [logs, setLogs] = useState('');

    useEffect(() => {
        ctx.readLog()
            .then(res => {
                if (res.status === 200) {
                    return res.text();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    ctx.logout();
                } else {
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setLogs(jsonRes);
                    let logs = document.getElementById('logs');
                    logs.scrollTop = logs.scrollHeight;
                }
            })
            .catch(e => {
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.container} >
            <Typography variant="h4" color='textSecondary' className={classes.title}>{"Logs"}</Typography>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <Typography id="logs" variant='body2' color='textSecondary' style={{ padding: theme.spacing(2), maxHeight: '600px', overflowY: 'scroll', overflowX: 'hidden' }}>
                            {logs}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Logs;