import { Button, Card, CardHeader, CircularProgress, Grid, makeStyles, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { properties } from '../configs/properties';
import { useSnackbar } from 'notistack';
import CustomContext from '../context/CustomContext';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(8)
    },
    innerContainer: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2)
    },
    fullWidth: {
        width: "100%",
    },
    submit: {
        marginTop: theme.spacing(4),
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
    logoAzienda: {
        // height: '100%',
        // width: '100%'
    }
}))

const validImageTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg"
]

const AdminSettings = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [azienda, setAzienda] = useState('');
    const [logo, setLogo] = useState('');
    const [logoUrl, setLogoUrl] = useState('');
    const [via, setVia] = useState('');
    const [telefono, setTelefono] = useState('');

    const handleChangeVia = (e) => {
        setVia(e.target.value);
    }

    const handleChangeTelefono = (e) => {
        setTelefono(e.target.value);
    }

    const handleChangeAzienda = (e) => {
        setAzienda(e.target.value);
    }

    const validateFile = (file) => {
        return validImageTypes.includes(file?.type);
    }

    const handleChangeLogo = (e) => {
        setLogo(e.target.value);
        if (e.target.value && validateFile(e.target.files[0])) {
            setLoading(true);
            const formData = new FormData();
            formData.append(
                'file',
                e.target.files[0],
                e.target.files[0].name
            );
            ctx.uploadLogoAzienda(formData)
                .then(res => {
                    if (res.status === 200) {
                        return res.json();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(jsonRes => {
                    if (jsonRes) {
                        setLoading(false);
                        setLogoUrl(jsonRes);
                        enqueueSnackbar('Upload avvenuto con successo.', { variant: 'success' })
                    }
                })
                .catch(e => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        } else {
            enqueueSnackbar('File non valido.', { variant: 'warning' })
        }
    }

    const handleClickSave = () => {
        setLoading(true);
        ctx.saveAdminSettings({ azienda: azienda, via: via, telefono: telefono })
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setLoading(false);
                    enqueueSnackbar(properties.successMessage, { variant: 'success' });
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        setLoading(true);
        ctx.getAdminSettingsEnum()
            .then(result => {
                if (result?.status === 200) {
                    return result.json();
                } else if (result?.status === 401) {
                    setLoading(false);
                    ctx.logout();
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setLoading(false);
                    setAzienda(jsonRes?.azienda ? jsonRes.azienda : '');
                    setLogoUrl(jsonRes?.logoAzienda ? properties.publicMediaPath + jsonRes.logoAzienda : '');
                    setVia(jsonRes?.via ? jsonRes.via : '');
                    setTelefono(jsonRes?.telefono ? jsonRes.telefono : '');
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }, [ctx, enqueueSnackbar]);

    return (
        <div className={classes.container}>
            <Typography variant="h4" color='textSecondary'>{"Impostazioni installatore"}</Typography>
            <Grid
                container
                spacing={3}
                justify="space-around"
                className={classes.innerContainer}
            >
                <Grid item lg={12} className={classes.fullWidth}>
                    <Card raised className={classes.card}>
                        <CardHeader
                            title="Info installatore"
                            titleTypographyProps={{ variant: "h6" }}
                        />
                        <Grid container>
                            <Grid
                                item
                                lg={6}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="azienda"
                                    label="Azienda"
                                    name="azienda"
                                    value={azienda}
                                    onChange={handleChangeAzienda}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="via"
                                    label="Via"
                                    name="via"
                                    value={via}
                                    onChange={handleChangeVia}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="telefono"
                                    label='Telefono'
                                    name="telefono"
                                    value={telefono}
                                    onChange={handleChangeTelefono}
                                />
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                className={classes.fullWidth}
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    paddingRight: theme.spacing(1),
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography variant='body1' color='textSecondary' align='center' style={{ marginTop: theme.spacing(1) }} >{'Logo azienda'}</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="logo"
                                    id="logo"
                                    value={logo}
                                    onChange={handleChangeLogo}
                                    type='file'
                                    InputProps={{
                                        inputProps: {
                                            accept: "image/*"
                                        }
                                    }}
                                    disabled={isLoading}
                                    style={{ marginTop: theme.spacing(1) }}
                                />
                                {logoUrl && <img src={`${logoUrl}`} style={{ maxHeight: '250px', padding: '1% 2%' }} alt='logo' />}
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

            <Button
                onClick={handleClickSave}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
            >
                {isLoading ? (
                    <CircularProgress size="2em" color='primary' />
                ) : (
                        <span style={{ fontWeight: "bold" }}>{"Salva"}</span>
                    )}
            </Button>
        </div>
    )
}

export default AdminSettings;