import { FaChartLine, FaClipboardList, FaCogs, FaFilePdf, FaFolderOpen, FaHome, FaUsers } from "react-icons/fa";
import CreateReport from "../pages/CreateReport";
import Dashboard from "../pages/Dashboard";
import SuperadminSettings from "../pages/SuperadminSettings";
import AdminSettings from "../pages/AdminSettings";
import ReportList from "../pages/ReportList";
import CompleteProfile from "../pages/CompleteProfile";
import VoidDashboard from "../pages/VoidDashboard";
import Logs from "../pages/Logs";
import UserList from "../pages/UserList";
import Progress from "../pages/Progress";


export const superadminRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard
    },
    {
        key: 'report-list',
        title: 'Lista report',
        icon: FaFolderOpen,
        path: '/reportlist',
        component: ReportList
    },
    {
        key: 'user-list',
        title: 'Lista utenti',
        icon: FaUsers,
        path: '/userlist',
        component: UserList
    },
    {
        key: 'superadmin-settings',
        title: 'Impostazioni',
        icon: FaCogs,
        path: '/superadmin/impostazioni',
        component: SuperadminSettings
    }
];

export const adminRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard
    },
    {
        key: 'report-list',
        title: 'Lista report',
        icon: FaFolderOpen,
        path: '/reportlist',
        component: ReportList
    },
    {
        key: 'report',
        title: 'Report',
        icon: FaFilePdf,
        path: '/report',
        component: CreateReport
    },
    {
        key: 'progress',
        title: 'Andamento',
        icon: FaChartLine,
        path: '/progress',
        component: Progress
    },
    {
        key: 'admin-settings',
        title: 'Impostazioni',
        icon: FaCogs,
        path: '/admin/impostazioni',
        component: AdminSettings
    }
]

export const userRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard
    },
    {
        key: 'report-list',
        title: 'Lista report',
        icon: FaFolderOpen,
        path: '/reportlist',
        component: ReportList
    }
]

export const demoRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard
    },
    {
        key: 'superadmin-settings',
        title: 'Impostazioni superadmin',
        icon: FaCogs,
        path: '/superadmin/impostazioni',
        component: SuperadminSettings
    },
    {
        key: 'admin-settings',
        title: 'Impostazioni admin',
        icon: FaCogs,
        path: '/admin/impostazioni',
        component: AdminSettings
    }
]

export const completeProfileRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: VoidDashboard
    },
    {
        key: 'complete-profile-settings',
        title: 'Completa profilo',
        icon: FaCogs,
        path: '/completa-profilo',
        component: CompleteProfile
    }
]

export const devRoutes = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        icon: FaHome,
        path: '/',
        exact: true,
        component: Dashboard
    },
    {
        key: 'logs',
        title: 'Log',
        icon: FaClipboardList,
        path: '/log',
        component: Logs
    }
]