import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Button, Card, CardHeader, CircularProgress, Collapse, Fab, Grid, makeStyles, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import CustomContext from '../context/CustomContext';
import { useSnackbar } from 'notistack';
import { properties } from '../configs/properties';
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "@material-ui/pickers";
import "date-fns";
import itLocale from "date-fns/locale/it";
import { FaChevronDown, FaChevronUp, FaEyeDropper, FaMapMarkerAlt, FaPrint, FaUserCircle } from 'react-icons/fa';
import { Autocomplete } from '@material-ui/lab';
import Chart from 'react-google-charts';


const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    fullWidth: {
        width: "100%",
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardContent: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', padding: theme.spacing(2)
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3)
    }
}))

const colors = [
    '#647CB8',
    '#3F4F75',
    '#84A4F5',
    '#1D2436',
    '#7693DB',
    '#4D5AB8',
    '#313975',
    '#6777F5',
    '#161A36',
    '#5D6CDB'
]

const chartLoader = () => {
    return (
        <div style={{ height: '300px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}><CircularProgress color='primary' /></div>
    )
}

const Progress = () => {
    const classes = useStyle();
    const theme = useTheme();
    const ctx = useContext(CustomContext);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(true);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [clienti, setClienti] = useState([]);
    const [cliente, setCliente] = useState(null);
    const [tipiAmbiente, setTipiAmbiente] = useState([]);
    const [tipoAmbiente, setTipoAmbiente] = useState(null);
    const [puntiCampionamento, setPuntiCampionamento] = useState([]);
    const [puntoCampionamento, setPuntoCampionamento] = useState(null);
    const [progressData, setProgressData] = useState(null);
    const [dateToError, setDateToError] = useState(false);
    const [dateFromError, setDateFromError] = useState(false);
    const [clienteError, setClienteError] = useState(false);
    const [tipoAmbienteError, setTipoAmbienteError] = useState(false);
    const [puntoCampionamentoError, setPuntoCampionamentoError] = useState(false);

    const handleChangeTipoAmbiente = (e, ta) => {
        setTipoAmbienteError(false);
        setTipoAmbiente(ta);
    }

    const handleChangePuntoCampionamento = (e, pc) => {
        setPuntoCampionamentoError(false);
        setPuntoCampionamento(pc);
    }

    const handleChangeDateFrom = (data) => {
        setDateFromError(false);
        if (data) {
            let formatted = new Date(moment(data).format('MM-DD-yyyy'));
            setDateFrom(formatted);
        } else {
            setDateFrom(data)
        }
    }

    const handleChangeDateTo = (data) => {
        setDateToError(false);
        if (data) {
            let formatted = new Date(moment(data).format('MM-DD-yyyy'));
            setDateTo(formatted);
        } else {
            setDateTo(data)
        }
    }

    const handleChangeCliente = (e, cliente) => {
        setClienteError(false);
        setCliente(cliente);
    }

    const handleClickResetFilter = () => {
        setDateFrom(null);
        setDateTo(null);
        setCliente(null);
        setTipoAmbiente(null);
        setPuntoCampionamento(null);
        setProgressData(null);
    }

    const validation = () => {
        let result = true;

        if (!dateFrom) {
            setDateFromError(true);
            result = false;
        }

        if (!dateTo) {
            setDateToError(true);
            result = false;
        }

        if (!cliente) {
            setClienteError(true);
            result = false;
        }

        if (!tipoAmbiente) {
            setTipoAmbienteError(true);
            result = false;
        }

        if (!puntoCampionamento) {
            setPuntoCampionamentoError(true);
            result = false;
        }

        return result;
    }

    const handleClickFilter = () => {
        if (validation()) {
            getData();
        }
    }

    const handleClickPrint = () => {
        if (progressData?.length > 0) {
            setLoading(true);
            let filters = {
                dateFrom: dateFrom ? moment(new Date(dateFrom).setDate(dateFrom.getDate() - 1)).format('yyyy-MM-DD') + 'T00:00:00.000Z' : null,
                dateTo: dateTo ? moment(dateTo).format('yyyy-MM-DD') + 'T00:00:00.000Z' : null,
                idUtenteUtilizzatore: cliente?.id,
                idTipoAmbiente: tipoAmbiente?.id,
                idPuntoCampionamento: puntoCampionamento.id
            }
            ctx.getProgressDataFile(filters)
                .then(res => {
                    if (res.status === 200) {
                        return res.blob();
                    } else if (res.status === 401) {
                        setLoading(false);
                        ctx.logout();
                        enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    } else {
                        setLoading(false);
                        enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                        return null;
                    }
                })
                .then(blob => {
                    if (blob) {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = 'andamento.pdf';
                        a.click();
                        setLoading(false);
                        enqueueSnackbar('File scaricato.', { variant: 'success' });
                    }
                })
                .catch(error => {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                })
        } else {
            enqueueSnackbar('Nessun risultato da stampare.', { variant: 'warning' })
        }
    }

    const getData = async () => {
        setLoading(true);
        let filters = {
            dateFrom: dateFrom ? moment(new Date(dateFrom).setDate(dateFrom.getDate() - 1)).format('yyyy-MM-DD') + 'T00:00:00.000Z' : null,
            dateTo: dateTo ? moment(dateTo).format('yyyy-MM-DD') + 'T00:00:00.000Z' : null,
            idUtenteUtilizzatore: cliente?.id,
            idTipoAmbiente: tipoAmbiente?.id,
            idPuntoCampionamento: puntoCampionamento.id
        }
        ctx.getProgressData(filters)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    setLoading(false);
                    ctx.logout();
                } else {
                    setLoading(false);
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setLoading(false);
                    setProgressData(jsonRes);
                }
            })
            .catch(e => {
                setLoading(false);
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    const getProgressEnums = () => {
        ctx.getProgressEnums()
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else if (res.status === 401) {
                    enqueueSnackbar(properties.expiredSessionMessage, { variant: 'info' });
                    ctx.logout();
                } else {
                    enqueueSnackbar(properties.errorMessage, { variant: 'error' });
                    return null;
                }
            })
            .then(jsonRes => {
                if (jsonRes) {
                    setClienti(jsonRes.clienti);
                    setTipiAmbiente(jsonRes.tipoAmbienteList);
                    setPuntiCampionamento(jsonRes.puntoCampionamentoList);
                }
            })
            .catch(e => {
                enqueueSnackbar(properties.errorMessage, { variant: 'error' });
            })
    }

    useEffect(() => {
        getProgressEnums();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
            <div className={classes.container}>
                <Typography variant="h4" color='textSecondary' style={{ marginBottom: theme.spacing(3) }}>{'Andamento'}</Typography>
                {
                    isLoading ?
                        <div className={classes.overlay}>
                            <CircularProgress color={"primary"} />
                        </div>
                        :
                        <div>

                            <Grid container style={{ marginBottom: theme.spacing(2) }} spacing={2} >
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography variant='h6' color='textSecondary'>
                                                    {'Filtri'}
                                                </Typography>
                                                {filtersVisible ? <FaChevronUp size={20} style={{ color: theme.palette.primary.main }} />
                                                    : <FaChevronDown size={20} style={{ color: theme.palette.primary.main }} />}
                                            </div>}
                                            onClick={() => { setFiltersVisible(!filtersVisible) }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Collapse in={filtersVisible}>
                                            <Grid item container xs={12} style={{ padding: theme.spacing(2) }}>
                                                <Grid item container md={9} className={classes.fullWidth}>

                                                    <Grid
                                                        item
                                                        md={4}
                                                        className={classes.fullWidth}
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                    >
                                                        {clienti.length > 0 &&
                                                            <Autocomplete
                                                                id="clienti"
                                                                options={clienti}
                                                                value={cliente}
                                                                onChange={handleChangeCliente}
                                                                getOptionLabel={(option) => (option.nome + ' ' + option.cognome)}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        label="Cliente"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        margin='normal'
                                                                        error={clienteError}
                                                                    />
                                                                }
                                                                renderOption={(option) =>
                                                                    <React.Fragment>
                                                                        <Avatar style={{ marginRight: '10px' }}>
                                                                            <FaUserCircle />
                                                                        </Avatar>
                                                                        {option.nome + ' ' + option.cognome}
                                                                    </React.Fragment>
                                                                }
                                                                ListboxProps={{
                                                                    style: {
                                                                        border: '1px solid rgba(0,0,0,0.2)',
                                                                        borderRadius: '3px'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        className={classes.fullWidth}
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                    >
                                                        {tipiAmbiente.length > 0 &&
                                                            <Autocomplete
                                                                id="tipi-ambiente"
                                                                options={tipiAmbiente}
                                                                value={tipoAmbiente}
                                                                onChange={handleChangeTipoAmbiente}
                                                                getOptionLabel={(option) => (option.nome)}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        label="Tipo ambiente"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        margin='normal'
                                                                        error={tipoAmbienteError}
                                                                    />
                                                                }
                                                                renderOption={(option) =>
                                                                    <React.Fragment>
                                                                        <Avatar style={{ marginRight: '10px' }}>
                                                                            <FaMapMarkerAlt />
                                                                        </Avatar>
                                                                        {option.nome}
                                                                    </React.Fragment>
                                                                }
                                                                ListboxProps={{
                                                                    style: {
                                                                        border: '1px solid rgba(0,0,0,0.2)',
                                                                        borderRadius: '3px'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        className={classes.fullWidth}
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                    >
                                                        {tipiAmbiente.length > 0 &&
                                                            <Autocomplete
                                                                id="punto-campionamento"
                                                                options={puntiCampionamento}
                                                                value={puntoCampionamento}
                                                                onChange={handleChangePuntoCampionamento}
                                                                getOptionLabel={(option) => (option.nome)}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        label="Punto campionamento"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        margin='normal'
                                                                        error={puntoCampionamentoError}
                                                                    />
                                                                }
                                                                renderOption={(option) =>
                                                                    <React.Fragment>
                                                                        <Avatar style={{ marginRight: '10px' }}>
                                                                            <FaEyeDropper />
                                                                        </Avatar>
                                                                        {option.nome}
                                                                    </React.Fragment>
                                                                }
                                                                ListboxProps={{
                                                                    style: {
                                                                        border: '1px solid rgba(0,0,0,0.2)',
                                                                        borderRadius: '3px'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        className={classes.fullWidth}
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                    >
                                                        <DatePicker
                                                            margin="normal"
                                                            id="data_da"
                                                            format="dd/MM/yyyy"
                                                            value={dateFrom}
                                                            onChange={handleChangeDateFrom}
                                                            helperText="Data da"
                                                            fullWidth
                                                            cancelLabel="ANNULLA"
                                                            clearLabel='SVUOTA'
                                                            style={{
                                                                paddingLeft: theme.spacing(1),
                                                                paddingRight: theme.spacing(1),
                                                            }}
                                                            maxDate={dateTo}
                                                            clearable
                                                            error={dateFromError}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        md={4}
                                                        className={classes.fullWidth}
                                                        style={{
                                                            paddingLeft: theme.spacing(1),
                                                            paddingRight: theme.spacing(1),
                                                        }}
                                                    >
                                                        <DatePicker
                                                            margin="normal"
                                                            id="data_a"
                                                            format="dd/MM/yyyy"
                                                            value={dateTo}
                                                            onChange={handleChangeDateTo}
                                                            helperText="Data a"
                                                            fullWidth
                                                            cancelLabel="ANNULLA"
                                                            clearLabel='SVUOTA'
                                                            style={{
                                                                paddingLeft: theme.spacing(1),
                                                                paddingRight: theme.spacing(1),
                                                            }}
                                                            minDate={dateFrom}
                                                            clearable
                                                            error={dateToError}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={3}
                                                    className={classes.fullWidth}
                                                    style={{
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                        display: 'flex',
                                                        alignItems: 'flex-end',
                                                        justifyContent: 'flex-end'
                                                    }}
                                                >
                                                    <Button
                                                        onClick={handleClickResetFilter}
                                                        variant="contained"
                                                        disabled={isLoading}
                                                        style={{ backgroundColor: isLoading ? 'lightgray' : theme.palette.primary.main, color: 'white' }}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                                <span style={{ fontWeight: "bold" }}>{"Reset"}</span>
                                                            )}
                                                    </Button>
                                                    <Button
                                                        onClick={handleClickFilter}
                                                        variant="contained"
                                                        disabled={isLoading}
                                                        style={{ marginLeft: theme.spacing(1), backgroundColor: isLoading ? 'lightgray' : theme.palette.primary.main, color: 'white' }}
                                                    >
                                                        {isLoading ? (
                                                            <CircularProgress size="2em" color='primary' />
                                                        ) : (
                                                                <span style={{ fontWeight: "bold" }}>{"Filtra"}</span>
                                                            )}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<Typography variant='h6' color='textSecondary'>
                                                {'Conta Microrganismi'}
                                            </Typography>}
                                        />
                                        <Grid className={classes.cardContent}>
                                            {
                                                progressData?.length > 0 ?
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="Bar"
                                                        loader={chartLoader()}
                                                        data={[
                                                            ['', '']
                                                        ].concat(
                                                            progressData?.map((pd, key) => {
                                                                return [moment(new Date(pd.data)).format('DD/MM/yyyy'), pd.contaMicrorganismi];
                                                            }))}
                                                        options={{
                                                            title: '',
                                                            is3D: true,
                                                            colors: colors,
                                                            legend: { position: 'none' },
                                                            chartArea: { width: '100%' }
                                                        }}
                                                    />
                                                    :
                                                    <Typography variant='h6' color='textSecondary'>{'Nessun risultato'}</Typography>
                                            }
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<Typography variant='h6' color='textSecondary'>
                                                {'Conta Lieviti'}
                                            </Typography>}
                                        />
                                        <Grid className={classes.cardContent}>
                                            {
                                                progressData?.length > 0 ?
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="Bar"
                                                        loader={chartLoader()}
                                                        data={[
                                                            ['', '']
                                                        ].concat(
                                                            progressData?.map((pd, key) => {
                                                                return [moment(new Date(pd.data)).format('DD/MM/yyyy'), pd.contaLieviti];
                                                            }))}
                                                        options={{
                                                            title: '',
                                                            is3D: true,
                                                            colors: colors,
                                                            legend: { position: 'none' },
                                                            chartArea: { width: '100%' }
                                                        }}
                                                    />
                                                    :
                                                    <Typography variant='h6' color='textSecondary'>{'Nessun risultato'}</Typography>
                                            }
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card raised style={{ borderRadius: theme.spacing(0.5) }}>
                                        <CardHeader
                                            title={<Typography variant='h6' color='textSecondary'>
                                                {'Controllo Microbiologico'}
                                            </Typography>}
                                        />
                                        <Grid className={classes.cardContent}>
                                            {
                                                progressData?.length > 0 ?
                                                    <Chart
                                                        width={'100%'}
                                                        height={'300px'}
                                                        chartType="Bar"
                                                        loader={chartLoader()}
                                                        data={[
                                                            ['', '']
                                                        ].concat(
                                                            progressData?.map((pd, key) => {
                                                                return [moment(new Date(pd.data)).format('DD/MM/yyyy'), pd.controlloMicrobiologico];
                                                            }))}
                                                        options={{
                                                            title: '',
                                                            is3D: true,
                                                            colors: colors,
                                                            legend: { position: 'none' },
                                                            chartArea: { width: '100%' }
                                                        }}
                                                    />
                                                    :
                                                    <Typography variant='h6' color='textSecondary'>{'Nessun risultato'}</Typography>
                                            }
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>

                        </div>
                }
            </div>
            <Tooltip title='Stampa'>
                <Fab color='primary' className={classes.fab} onClick={handleClickPrint} >
                    <FaPrint />
                </Fab>
            </Tooltip>
        </MuiPickersUtilsProvider>
    )
}

export default Progress;