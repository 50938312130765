import { Link, Typography, useTheme } from '@material-ui/core';
import React from 'react';

const NotFound = () => {
    const theme = useTheme();

    return (
        <div style={{ backgroundColor: theme.palette.primary.main, flexDirection: 'column', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h3' color='secondary' style={{ textAlign: 'center' }}>
                {'404'}
            </Typography>
            <Typography variant='h5' style={{ color: 'white', textAlign: 'center', marginTop: theme.spacing(3) }}>
                {'Pagina non trovata'}
            </Typography>
            <Typography variant="body2" color="secondary" align="center" style={{marginTop: theme.spacing(3)}}>
                <Link color="inherit" href="/">
                    {'Torna alla home'}
                </Link>
            </Typography>
        </div>
    )
}

export default NotFound;