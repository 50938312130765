import { createContext } from 'react';
import { properties } from '../configs/properties';
import {
    superadminRoutes,
    adminRoutes,
    userRoutes,
    demoRoutes, completeProfileRoutes, devRoutes
} from '../configs/routes';
import Cookies from 'js-cookie';

const CustomContext = createContext({
    login: async (username, password) => {
        return await fetch(`${properties.API_URL}/auth/login`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            }
        ).catch(e => { console.error(e) })
    },
    logout: () => {
        Cookies.remove(properties.tokenKey);
        window.location = '/login';
    },
    getUserCookie: () => {
        return Cookies.getJSON('refineair-user');
    },
    setCookieUserCompleted: () => {
        let user = Cookies.getJSON('refineair-user');
        user.resetPw = false;
        Cookies.set('refineair-user', user);
        window.location = '/';
    },
    getRoutesByRole: () => {
        var user = Cookies.getJSON('refineair-user');
        if (user) {
            if (user.resetPw === true) {
                return completeProfileRoutes;
            } else {
                switch (user.accountTipologiaDomain.nome) {
                    case 'superadmin': {
                        return superadminRoutes;
                    }
                    case 'admin': {
                        return adminRoutes;
                    }
                    case 'user': {
                        return userRoutes;
                    }
                    case 'demo': {
                        return demoRoutes;
                    }
                    case 'dev': {
                        return devRoutes;
                    }
                    default: {
                        return userRoutes;
                    }
                }
            }
        } else {
            return [];
        }
    },
    getCreateReportEnums: async () => {
        return await fetch(`${properties.API_URL}/enum/createReport`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    uploadLogoAzienda: async (formData) => {
        return fetch(
            `${properties.API_URL}/user/upload`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: formData
            }
        ).catch(e => { console.error(e) })
    },
    getAdminSettingsEnum: async () => {
        return fetch(
            `${properties.API_URL}/enum/adminSettings`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    saveAdminSettings: async (body) => {
        return fetch(
            `${properties.API_URL}/user/saveAdminSettings`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(body)
            }
        ).catch(e => { console.error(e) })
    },
    saveNewReport: async (report) => {
        return fetch(
            `${properties.API_URL}/report/insert`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(report)
            }
        ).catch(e => { console.error(e) })
    },
    getUserLogged: async () => {
        return fetch(
            `${properties.API_URL}/user/findByToken`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getReportList: async (filters) => {
        return fetch(
            `${properties.API_URL}/report/list`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    getReportFile: async (idReport) => {
        return fetch(
            `${properties.API_URL}/report/pdf/${idReport}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getTipoAmbienteAnalytics: async () => {
        return fetch(
            `${properties.API_URL}/tipoAmbiente/getAnalytics`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getDashboardAnalytics: async () => {
        return fetch(
            `${properties.API_URL}/dashboard/analytics`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getSuperadminSettingsEnums: async () => {
        return fetch(
            `${properties.API_URL}/enum/superadminSettings`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    insertInstaller: async (installer) => {
        return fetch(
            `${properties.API_URL}/user/generateInstaller`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(installer)
            }
        ).catch(e => { console.error(e) })
    },
    emailExists: async (email) => {
        return fetch(
            `${properties.API_URL}/user/emailExists/${email}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    generateUsers: async (body) => {
        return fetch(
            `${properties.API_URL}/user/generateUsers`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(body)
            }
        ).catch(e => { console.error(e) })
    },
    completeProfile: async (completeProfileRequest) => {
        return fetch(
            `${properties.API_URL}/user/completeProfile`,
            {
                method: 'PUT',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(completeProfileRequest)
            }
        ).catch(e => { console.error(e) })
    },
    readLog: async () => {
        return fetch(
            `${properties.API_URL}/log/read`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    superadminList: async (filters) => {
        return fetch(
            `${properties.API_URL}/user/superadminList`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    saveTipoAmbiente: async (tipoAmbiente) => {
        return fetch(
            `${properties.API_URL}/tipoAmbiente/add`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(tipoAmbiente)
            }
        ).catch(e => { console.error(e) })
    },
    savePuntoCampionamento: async (puntoCampionamento) => {
        return fetch(
            `${properties.API_URL}/puntoCampionamento/add`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(puntoCampionamento)
            }
        ).catch(e => { console.error(e) })
    },
    getReportListEnums: async () => {
        return fetch(
            `${properties.API_URL}/enum/reportList`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProgressEnums: async () => {
        return fetch(
            `${properties.API_URL}/enum/progress`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProgressData: async (filters) => {
        return fetch(
            `${properties.API_URL}/report/progress`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    },
    tipoAmbienteExists: async (nome) => {
        return fetch(
            `${properties.API_URL}/tipoAmbiente/exists/${nome}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    puntoCampionamentoExists: async (nome) => {
        return fetch(
            `${properties.API_URL}/puntoCampionamento/exists/${nome}`,
            {
                method: 'GET',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                }
            }
        ).catch(e => { console.error(e) })
    },
    getProgressDataFile: async (filters) => {
        return fetch(
            `${properties.API_URL}/report/pdf/progress`,
            {
                method: 'POST',
                headers: {
                    'secretKey': properties.secretKey,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get(properties.tokenKey)}`
                },
                body: JSON.stringify(filters)
            }
        ).catch(e => { console.error(e) })
    }
})

export default CustomContext;