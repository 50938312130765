import { Card, CardHeader, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4)
    },
    card: {
        padding: theme.spacing(1),
        borderRadius: "10px",
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    cardContent: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', padding: theme.spacing(2)
    }
}))

const VoidDashboard = () => {
    const classes = useStyle();
    const theme = useTheme();

    return (
        <div className={classes.container} >
            <Typography variant="h4" color='textSecondary' className={classes.title}>{"Dashboard"}</Typography>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12} >
                    <Card
                        raised
                        className={classes.card}
                    >
                        <CardHeader
                            title={"Attenzione"}
                            titleTypographyProps={{
                                variant: 'h6',
                                color: 'textSecondary'
                            }}
                        />
                        <Typography variant='h6' color='textPrimary' style={{padding: theme.spacing(2)}}>
                            {'Completa il profilo per accedere ai tuoi dati.'}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default VoidDashboard;